<template>
  <v-container fluid class="driver-sidebar">
    <v-form ref="base">
      <v-card class="card main-container">
        <v-layout
          row
          wrap
          justify-center
          align-end
          style="max-width: 1200px; margin: 0 auto"
        >
          <v-flex column xs12>
            <div class="avatar-group">
              <input
                :id="`users-form-input-upload-file`"
                :disabled="disabled"
                accept="image/*"
                class="avatar-input"
                type="file"
                @change="uploadFile"
              />
              <v-avatar class="the-avatar" size="90">
                <img v-if="avatarLink" :src="avatarLink" />
                <v-icon v-if="!avatarLink" color="blue" size="50">
                  person_outline
                </v-icon>
              </v-avatar>
              <div v-if="!avatarLink" class="avatar-text">
                Add Profile Image
              </div>
              <div v-else class="avatar-text">Change Profile Image</div>
            </div>
          </v-flex>
          <v-flex column xs12>
            <CRInput
              :id="`users-form-input-first-name`"
              v-model="row.firstName"
              :disabled="disabled"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'First Name Is Required',
                  error: 'First Name Is Required',
                }),
              ]"
              floatinglabel="First Name"
              @change="syncInput"
            />
          </v-flex>
          <v-flex column xs12>
            <CRInput
              :id="`users-form-input-last-name`"
              v-model="row.lastName"
              :disabled="disabled"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Last Name Is Required',
                  error: 'Last Name Is Required',
                }),
              ]"
              floatinglabel="Last Name"
              @change="syncInput"
            />
          </v-flex>
          <v-flex column xs12>
            <CRInput
              :id="`users-form`"
              v-model="row.email"
              :disabled="disabled"
              :rules="[
                isRequired(true, validateEmail, {
                  req: 'Email Required',
                  error: 'Email must be valid',
                }),
              ]"
              floatinglabel="Email"
              :class="{ 'left-input': $cr.breakpoint.smAndUp }"
              style="margin-top: 2px"
              @change="syncInput"
            />
          </v-flex>
          <v-flex column xs12>
            <label>User Group</label>
            <div id="users-form-select-group-container">
              <CRSelect
                :id="`users-form-select-group-id`"
                v-model="row.groupId"
                solo
                flat
                item-value="groupId"
                item-text="groupName"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'User Group Is Required',
                    error: 'User Group Is Required',
                  }),
                ]"
                :disabled="
                  isModeView || isModeDriverAdd || true ? true : groupIdVerify
                "
                :items="getGroups()"
                @change="getGroupAndSyncInput"
              />
            </div>
          </v-flex>
          <v-flex xs6 />
          <v-checkbox
            v-if="row.groupId === 1 || row.groupId === 2"
            :id="`users-form-checkbox-assign-tickets`"
            v-model="canBeAssignedTickets"
            class="amenity-box"
            label="This user can be assigned tickets"
            :disabled="disabled"
            @change="assignTicketCheckBox"
          />
        </v-layout>
        <UsersFormDriverSettingsTab
          id="users-form-driver-settings-tab-sidebar"
          ref="driverSettings"
          :mode="mode"
          :row="row"
          :user-id="id"
          :is-user-driver="isUserDriver"
          :selected-roles="roleSelections"
          :is-driver-sidebar="true"
          :minimum-requirements="minimumRequirements"
          @user-role-change="roleSelections = $event"
        />
      </v-card>
    </v-form>
    <div class="cr-sidebar-dialog--button-spacer"></div>
    <CRButton
      class="driver-sidebar--action-btn"
      color="primary"
      :disabled="loading"
      @click="submit"
    >
      <span v-if="!loading">Add Driver</span>
      <CRProgressCircular v-else :size="27" />
    </CRButton>
  </v-container>
</template>
<script>
import UsersSchema from '@/components/UsersSchema'
import { EventBus } from '@/utils/event-bus'
import { authComputed } from '@/state/helpers'
import { baseUrl } from '@/utils/env'
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'
import { mapActions, mapGetters } from 'vuex'
import UsersFormDriverSettingsTab from '@/components/UsersFormDriverSettingsTab.vue'
import UsersFormDriverTimeOffList from '@/components/UsersFormDriverTimeOffList.vue'
import DefaultScheduling from '@/components/DefaultScheduling.vue'
import UsersFormRoleTab from '@/components/UsersFormRoleTab.vue'
import UserAuditList from '@/components/UserAuditList.vue'
import drivers from '@/services/drivers'
import companies from '@/services/companies'
import roles from '@/services/roles'
import uuid from 'uuid'
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'

export default {
  name: 'UsersForm',
  components: { UsersFormDriverSettingsTab },
  props: {
    id: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
    companyId: {
      type: Number,
      default: () => null,
    },
    minimumRequirements: {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    return {
      UsersSchema,
      tabs: [
        {
          label: 'Driver Settings',
          ref: 'driverSettings',
          component: UsersFormDriverSettingsTab,
        },
        {
          label: 'Default Scheduling',
          ref: 'scheduling',
          component: DefaultScheduling,
        },
        {
          label: 'Driver Time-Off',
          ref: 'timeoff',
          component: UsersFormDriverTimeOffList,
        },
        {
          label: 'Logs',
          ref: 'logs',
          component: UserAuditList,
        },
      ],
      loading: false,
      disabled: false,
      selectModels: {},
      avatarLink: null,
      uploadedFile: null,
      row: {
        companyId: null,
        companyName: '',
        email: '',
        phone: '',
        groupId: null,
        applicationId: 3,
      },
      error: null,
      layout: [],
      errmsg: '',
      displayerr: false,
      groupIdVerify: false,
      roles: [],
      roleSelections: [],
      canBeAssignedTickets: false,
      oldGroupId: null,
      treatAsDriver: false,
      isUserDriver: false,
      vehicleTypes: [],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      userState: 'users/getUser',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    isModeUserAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeReferralAdd() {
      return this.mode === 'referralAdd'
    },
    isModeDriverAdd() {
      return this.mode === 'driverAdd'
    },
    isAdmin() {
      return this.currentUser?.group?.groupId === 1
    },
    emptyNameFields() {
      var isFirstNameEmpty =
        this.row.firstName == null || this.row.firstName === ''
      var isLastNameEmpty =
        this.row.lastName !== null || this.row.lastName !== ''
      return isFirstNameEmpty && isLastNameEmpty
    },
    filteredTabs() {
      return this.tabs
    },
  },
  watch: {
    userState: async function (state) {
      if (state.driverInfoDTO === null) {
        const resetDriverInfoDTO = {
          phoneNumber: '',
          licenseNumber: '',
          licenseState: '',
          licenseExpirationMonth: null,
          licenseExpirationYear: null,
          drugTestNumber: null,
          drugTestExpiration: null,
          notes: '',
          driverSupportedVehiclesDTO: deepClone(this.vehicleTypes),
        }

        await this.$store.dispatch(
          'users/setUserDriverInfoDTO',
          resetDriverInfoDTO
        )
      }
    },
    'row.groupId'(val) {
      if (val === 4) {
        this.isUserDriver = true
      } else {
        this.isUserDriver =
          this.roleSelections.includes('is_driver') ||
          this.isModeReferralAdd ||
          this.isModeDriverAdd
      }
    },
  },
  metaInfo() {
    const meta = {}
    if (this.isModeEdit) {
      meta.title = `Edit: ${this.row.firstName ? this.row.firstName : ''} ${
        this.row.lastName ? this.row.lastName : ''
      }`
    }

    if (this.isModeView) {
      meta.title = `${this.row.firstName ? this.row.firstName : ''} ${
        this.row.lastName ? this.row.lastName : ''
      }`
    }
    return meta
  },
  async mounted() {
    if (this.isModeReferralAdd) {
      this.row.companyId = this.companyId
      const companyResponse = await companies.getCompany(this.row.companyId)
      const companyData = companyResponse.data.company
      this.row.companyName = companyData.name
      this.row.groupId = 4
      const randomEmail = uuid.v4().substring(0, 8)
      this.row.email = `${randomEmail}@charterup.com`
      return
    }

    await this.getVehicleTypes()

    this.groupIdVerify =
      this.currentUser && this.currentUser.group.groupId
        ? this.currentUser.group.groupId !== 1
        : true
    this.disabled = this.isModeView
    let driver = JSON.parse(JSON.stringify(UsersSchema.driver))
    driver.treatAsDriver = false

    let user = {}
    if (!this.isModeUserAdd && !this.isModeReferralAdd && !this.isModeDriverAdd) {
      user = await this.$store
        .dispatch('users/getUser', this.id)
        .then((data) => {
          return data.data.user
        })
        .catch(() => ({}))

      let driver
      const driverInfo = await drivers
        .getDriver(this.id)
        .then((data) => data.data)
        .catch(() => ({}))
      if (driverInfo?.successful && driverInfo.driver) {
        driver = driverInfo.driver
        driver.treatAsDriver = true
      }

      const driverUserPhotoDTOs = driver?.userPhotoDTOs
      this.avatarLink = driverUserPhotoDTOs?.[0]
        ? `https://${baseUrl(null)}${
            driverUserPhotoDTOs[driverUserPhotoDTOs.length - 1].imagePath
          }`
        : null
    }

    this.row = Object.assign({}, user, driver)

    this.row.groupId = driver.groupId || 4
    this.oldGroupId = this.row.groupId

    await this.$store.dispatch('users/setInitialUser', this.row)

    if (this.isModeUserAdd || this.isModeDriverAdd) {
      this.row.companyName = this.currentUser.companyName
      this.row.companyId = this.currentUser.companyId

      if (this.isAdmin) {
        const targetCompany = await companies
          .getCompany(this.companyId || this.currentUser.companyId)
          .then((data) => data.data?.company)
          .catch((e) => {
            this.row.companyName = this.currentUser.companyName
            this.row.companyId = this.currentUser.companyId
          })

        this.row.applicationId = targetCompany.cupopsEnabled ? 3 : 1
        this.row.companyName = targetCompany.name
        this.row.companyId = this.companyId || this.currentUser.companyId
      }
    }

    const roleResponse = await roles.getRoles({
      page: 1,
      pageSize: -1,
    })
    this.roles = roleResponse?.data?.resultList

    if (this.id) {
      const userRoleResponse = await roles.getUserRoles(this.id)
      const userRoles = userRoleResponse?.data?.roles
      userRoles.forEach((role) => this.roleSelections.push(role.roleName))
    }

    this.canBeAssignedTickets = this.roleSelections.includes(
      'can_be_assigned_tickets'
    )

    this.isUserDriver =
      this.roleSelections.includes('is_driver') ||
      this.isModeReferralAdd ||
      this.isModeDriverAdd

    if (this.canModifyUserRoles) {
      this.tabs.push({
        label: 'Roles',
        ref: 'roles',
        component: UsersFormRoleTab,
      })
    }

    EventBus.$on('user-driver-checkbox-changed', (event) => {
      this.isUserDriver = event
    })
  },
  methods: {
    isRequired,
    isNotEmpty,
    validateEmail,
    ...mapActions({ showAlert: 'app/showAlert' }),
    displayErr(e, status) {
      this.showAlert({
        message: e,
        type: 'error',
      })
    },
    uploadFile(evt) {
      const file = evt.target.files[0]
      this.avatarLink = URL.createObjectURL(file)

      const formData = new FormData()
      formData.append('file', file)
      this.uploadedFile = formData
    },
    changePassword() {
      this.$store.dispatch('app/openDialog', {
        component: () => import('@/components/ChangePasswordForm.vue'),
        data: {
          userId: this.id,
          isAdmin: this.isAdmin,
        },
      })
    },
    disableAndRevert() {
      this.disabled = true
      this.$router.push({ name: 'users.view', params: { id: this.id } })
    },
    async getGroupAndSyncInput() {
      if (!this.isModeEdit) {
        return
      }
      if (this.row.groupId !== this.oldGroupId) {
        let determineRoles = {}
        determineRoles.userId = this.id
        determineRoles.newUserGroupId = this.row.groupId
        determineRoles.oldUserGroupId = this.oldGroupId
        determineRoles.newRoleNames = this.roleSelections

        const userRolesResponse = await this.$store
          .dispatch('users/determineRoles', {
            payload: determineRoles,
          })
          .catch((_) => ({}))
        this.roleSelections = userRolesResponse.data?.roleNames
        this.oldGroupId = this.row.groupId
      }
      this.syncInput()
    },
    async syncInput() {
      if (this.row.groupId === 4) {
        this.row.treatAsDriver = true
      }
      await this.$store.dispatch('users/setUser', this.row)
    },
    async submit() {
      this.loading = true
      const baseValidate = this.$refs.base.validate()
      let driverSettingsValidate = false
      if (
        this.isModeReferralAdd ||
        (!this.isModeReferralAdd &&
          this.$refs?.driverSettings?.[0]?.$refs?.driverSettings === undefined)
      ) {
        driverSettingsValidate = true
      } else {
        if (this.isModeReferralAdd) {
          driverSettingsValidate = this.$refs.driverSettings.$refs.driverSettings.validate()
        } else {
          driverSettingsValidate = this.$refs.driverSettings[0].$refs.driverSettings.validate()
        }
      }
      if (driverSettingsValidate === false || baseValidate === false) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be Required. or are invalid.',
          true
        )
        return
      }

      let fetchEmail = await this.$store.dispatch(
        'users/checkEmail',
        this.row.email
      )
      let doesEmailExist = fetchEmail?.data?.exists

      if (doesEmailExist && (this.isModeUserAdd || this.isModeDriverAdd)) {
        this.displayErr('Email already exists, please use another one.', true)
        this.loading = false
        return
      } else if (doesEmailExist && this.isModeReferralAdd) {
        while (doesEmailExist) {
          const newRandomEmail = uuid.v4().substring(0, 8)
          this.row.email = `${newRandomEmail}@charterup.com`
          fetchEmail = await this.$store.dispatch(
            'users/checkEmail',
            this.row.email
          )
          doesEmailExist = fetchEmail?.data?.exists
        }
      }

      const copyOfState = JSON.parse(JSON.stringify(this.userState))
      copyOfState.groupId = this.row.groupId

      if (
        copyOfState.groupId !== 4 &&
        !this.$refs?.driverSettings?.[0]?.isDriver
      ) {
        if (copyOfState.driverInfo) {
          copyOfState.driverInfo.drugTestExpiration = DateTime.fromISO(
            copyOfState.drugTestExpiration
          ).toISODate()
        }
      }
      if (
        copyOfState.groupId === 4 ||
        this.$refs?.driverSettings?.[0]?.isDriver
      ) {
        copyOfState.phoneNumber = (copyOfState.phoneNumber || '').replace(
          /[^0-9]/g,
          ''
        )
        copyOfState.drugTestExpiration = DateTime.fromISO(
          copyOfState.drugTestExpiration
        ).toISODate()
      }
      copyOfState.userRoleNames = this.roleSelections
      if (
        this.canBeAssignedTickets &&
        !this.roleSelections.includes('can_be_assigned_tickets')
      ) {
        copyOfState.userRoleNames.push('can_be_assigned_tickets')
      }

      if (this.isUserDriver || this.isModeDriverAdd) {
        this.roleSelections.push('is_driver')
      } else if (
        this.roleSelections.includes('is_driver') &&
        this.newUserGroupId !== 4
      ) {
        this.roleSelections.splice(this.roleSelections.indexOf('is_driver'), 1)
      }

      try {
        let action

        if (copyOfState.treatAsDriver) {
          action =
            this.isModeUserAdd || this.isModeReferralAdd || this.isModeDriverAdd
              ? 'drivers/addDriver'
              : 'drivers/modifyDriverV3'
          if (action === 'drivers/modifyDriverV3') {
            await drivers.makeDriver(this.id)
          }
        } else {
          action = this.isModeUserAdd ? 'users/createUser' : 'users/updateUser'
          if (this.roleSelections.includes('is_driver')) {
            if (this.isModeUserAdd) {
              action = 'drivers/addDriver'
            } else {
              await drivers.makeDriver(this.id)
            }
          }
        }

        if (!this.roleSelections.includes('is_driver') && this.id) {
          await drivers.deactivateDriver(this.id)
        }

        let updatedUser
        if (action === 'drivers/addDriver') {
          updatedUser = await drivers.addDriver({
            id: this.id,
            payload: copyOfState,
          })
        } else if (action === 'drivers/modifyDriverV3') {
          updatedUser = await drivers.modifyDriverV3({
            id: this.id,
            payload: copyOfState,
          })
        } else {
          updatedUser = await this.$store.dispatch(action, {
            id: this.id,
            payload: copyOfState,
          })
        }

        if (this.uploadedFile) {
          const userId =
            updatedUser.data === 'number'
              ? updatedUser.data
              : updatedUser.data?.user?.userId
          const driverId = typeof userId === 'number' ? userId : this.id
          await drivers
            .uploadDriverPhoto({
              payload: this.uploadedFile,
              driverId,
            })
            .catch(() => ({}))
        }
      } catch (e) {
        this.loading = false
        if (e.message.includes('403')) {
          this.displayErr('You are not authorized to edit this user.', true)
        } else {
          this.displayErr(e, true)
        }
        return
      }

      this.loading = false
      this.disabled = !this.disabled

      this.$store.dispatch('app/closeDialog')
      EventBus.$emit(
        'successful-add-driver',
        `${this.row.firstName} ${this.row.lastName}`
      )
      return
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.$router.push({ name: 'users.edit', params: { id: this.id } })
      }

      this.disabled = !this.disabled
    },
    backToTable() {
      const routeName = this.$route.name === 'drivers.add' ? 'drivers' : 'users'
      this.$router.push({ name: routeName })
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
    assignTicketCheckBox() {
      if (
        this.roleSelections.includes('can_be_assigned_tickets') &&
        !this.canBeAssignedTickets
      ) {
        this.roleSelections.splice(
          this.roleSelections.indexOf('can_be_assigned_tickets'),
          1
        )
      }
      if (
        !this.roleSelections.includes('can_be_assigned_tickets') &&
        this.canBeAssignedTickets
      ) {
        this.roleSelections.push('can_be_assigned_tickets')
      }
    },
    getGroups() {
      var groups = UsersSchema.userGroups
      return groups
    },
    async getVehicleTypes() {
      const vehicleTypeResponse = await this.$store.dispatch(
        'types/getVehicleTypes'
      )
      const vehicleTypeData = vehicleTypeResponse.data.resultList
      const vehicleTypes = []
      vehicleTypeData.forEach((vehicleType) => {
        delete vehicleType.active
        delete vehicleType.companyId
        vehicleTypes.push({
          ...vehicleType,
          supported: false,
        })
      })
      this.vehicleTypes = vehicleTypes
    },
  },
}
</script>
<style lang="scss" scoped>
.driver-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 0px;

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    border-radius: 0;
    transition: 0.1s all ease-in-out;

    &:disabled {
      background: $gray-light;
    }
  }

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  ::v-deep .v-select .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
    color: $black;
  }

  ::v-deep
    .v-input--is-disabled:not(.v-input--checkbox):not(.v-input--switch)
    .v-input__control
    .v-input__slot {
    background-color: $input-border-gray !important;

    input:disabled {
      background-color: $input-border-gray !important;
    }
  }
}

.avatar-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;

  .avatar-input {
    position: absolute;
    z-index: 100;
    width: 150px;
    height: 150px;
    cursor: pointer;
    opacity: 0;
  }

  .avatar-text {
    margin-top: 2%;
    color: $primary;
    cursor: pointer;
  }

  .the-avatar {
    border: 3px solid $primary;
  }
}

@media screen and (max-width: 767px) {
  .form-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-direction: column-reverse;
    width: 100%;
  }
}

.amenities {
  display: grid;
  grid-template-rows: repeat(3, 35px);
  grid-template-columns: repeat(3, minmax(140px, 150px));
}

.card {
  padding: 30px;
  background-color: $white;
  border-radius: 15px;
}

::v-deep .input-space.description {
  margin-top: 20px;
}

.right-input {
  padding-left: 8px;
}

.left-input {
  padding-right: 8px;
}

::v-deep input[disabled],
::v-deep input:disabled,
::v-deep textarea[disabled] {
  cursor: not-allowed;
  background-color: $blue-dull !important;
}

.top-items {
  position: absolute;
  top: -5px;
  right: -8px;
}

.main-container {
  box-shadow: none !important;
}
</style>
