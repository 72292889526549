export default {
  driver: {
    firstName: null,
    lastName: null,
    email: '',
    groupName: '',
    companyName: '',
    groupId: null,
    companyId: null,
    locale: 'en_US',
    driverInfoDTO: {
      phoneNumber: '',
      licenseNumber: '',
      licenseState: '',
      licenseExpirationMonth: null,
      licenseExpirationYear: null,
      drugTestNumber: null,
      drugTestExpiration: null,
      notes: '',
      driverSupportedVehiclesDTO: [],
    },
  },
  vehicleOptions: [
    {
      vehicleTypeId: 1,
      label: 'Charter Bus',
      key: 'charter_bus',
      description: null,
      supported: false,
    },
    {
      vehicleTypeId: 2,
      label: 'Mini Bus',
      key: 'mini_bus',
      description: null,
      supported: false,
    },
    {
      vehicleTypeId: 3,
      label: 'Sprinter',
      key: 'sprinter',
      description: 'A Sprinter van or comparable',
      supported: false,
    },
    {
      vehicleTypeId: 4,
      label: 'Party Bus',
      key: 'party_bus',
      description: null,
      supported: false,
    },
    {
      vehicleTypeId: 5,
      label: 'Sedan',
      key: 'sedan',
      description: null,
      supported: false,
    },
    {
      vehicleTypeId: 6,
      label: 'SUV',
      key: 'suv',
      description: null,
      supported: false,
    },
    {
      vehicleTypeId: 7,
      label: 'Limousine',
      key: 'limousine',
      description: null,
      supported: false,
    },
    {
      vehicleTypeId: 8,
      label: 'Trolley',
      key: 'trolley',
      description: null,
      supported: false,
    },
  ],
  states: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  months: [
    {
      name: 'January',
      short: 'Jan',
      number: 0,
      days: 31,
    },
    {
      name: 'February',
      short: 'Feb',
      number: 1,
      days: 28,
    },
    {
      name: 'March',
      short: 'Mar',
      number: 2,
      days: 31,
    },
    {
      name: 'April',
      short: 'Apr',
      number: 3,
      days: 30,
    },
    {
      name: 'May',
      short: 'May',
      number: 4,
      days: 31,
    },
    {
      name: 'June',
      short: 'Jun',
      number: 5,
      days: 30,
    },
    {
      name: 'July',
      short: 'Jul',
      number: 6,
      days: 31,
    },
    {
      name: 'August',
      short: 'Aug',
      number: 7,
      days: 31,
    },
    {
      name: 'September',
      short: 'Sep',
      number: 8,
      days: 30,
    },
    {
      name: 'October',
      short: 'Oct',
      number: 9,
      days: 31,
    },
    {
      name: 'November',
      short: 'Nov',
      number: 10,
      days: 30,
    },
    {
      name: 'December',
      short: 'Dec',
      number: 11,
      days: 31,
    },
  ],
  userGroups: [
    {
      groupId: 1,
      groupName: 'Admin',
      key: 'admin',
    },
    {
      groupId: 2,
      groupName: 'User',
      key: 'user',
    },
    {
      groupId: 4,
      groupName: 'Driver',
      key: 'driver',
    },
    {
      groupId: 6,
      groupName: 'Agent',
      key: 'agent',
    }
  ],
}
