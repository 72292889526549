<template>
  <div>
    <v-container>
      <v-layout row class="default-scheduling">
        <v-flex>
          <v-form>
            <v-layout justify-space-between>
              <v-flex xs5 class="mt-3 ml-3">
                <div>
                  Set your default working hours for each day of the week
                </div>
              </v-flex>
              <v-flex xs2>
                <v-btn
                  :id="`default-scheduling-button-save-schedule`"
                  class="btn-primaryaction"
                  :disabled="isModeView"
                  @click="submit"
                >
                  Save Schedule
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row justify-start class="scheduling-form mt-4 ml-3">
              <v-flex xs2 justify-start>
                <b>Day</b>
              </v-flex>
              <v-flex xs3 justify-start>
                <b>Start</b>
              </v-flex>
              <v-flex xs3 offset-xs1 justify-start>
                <b>End</b>
              </v-flex>
              <v-flex xs1 offset-xs1 justify-start>
                <b>Availability</b>
              </v-flex>
            </v-layout>
            <v-layout
              v-for="(d, index) in schedule"
              :key="`${d.day}-row`"
              row
              align-baseline
              justify-start
              class="scheduling-form ml-3"
            >
              <v-flex xs2>
                <p>
                  {{ dayDescriptions[d.day] }}
                </p>
              </v-flex>
              <v-flex xs3>
                <CRSelect
                  :id="`default-scheduling-select-start-time-${index}`"
                  v-model="d.startTime"
                  class="cr-input"
                  :items="timeOptions"
                  prepend-inner-icon="$vuetify.icons.time"
                  flat
                  solo
                  :disabled="!d.available || isModeView"
                  :placeholder="`--:--`"
                  :menu-props="{ auto: true, overflowY: true }"
                />
              </v-flex>
              <v-flex xs3 offset-xs1>
                <CRSelect
                  :id="`default-scheduling-select-end-time-${index}`"
                  v-model="d.endTime"
                  class="cr-input"
                  :items="timeOptions"
                  prepend-inner-icon="$vuetify.icons.time"
                  flat
                  solo
                  :disabled="!d.available || isModeView"
                  :placeholder="`--:--`"
                  :menu-props="{ auto: true, overflowY: true }"
                />
              </v-flex>
              <v-flex xs1 offset-xs1>
                <v-switch
                  v-model="d.available"
                  :disabled="isModeView"
                  color="green"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
    <v-form v-if="isModeDispatch">
      <v-card class="cr-modal">
        <v-card-title>
          <h2>Driver Schedule</h2>
        </v-card-title>
        <v-card-text>
          <v-layout row class="default-scheduling">
            <v-flex>
              <v-form>
                <!-- <v-layout justify-space-between>
                    <v-flex xs5 class="mt-3 ml-3">
                      <div>
                        Set your default working hours for each day of the week
                      </div>
                    </v-flex>
                    <v-flex xs2>
                      <v-btn class="btn-primaryaction" @click="submit">
                        Save Schedule
                      </v-btn>
                    </v-flex>
                  </v-layout> -->
                <v-layout row justify-start class="scheduling-form mt-4 ml-3">
                  <v-flex xs2 justify-start>
                    <b>Day</b>
                  </v-flex>
                  <v-flex xs3 justify-start>
                    <b>Start</b>
                  </v-flex>
                  <v-flex xs3 offset-xs1 justify-start>
                    <b>End</b>
                  </v-flex>
                  <v-flex xs1 offset-xs1 justify-start>
                    <b>Availability</b>
                  </v-flex>
                </v-layout>
                <v-layout
                  v-for="(d, index) in schedule"
                  :key="`${d.day}-row`"
                  row
                  align-baseline
                  justify-start
                  class="scheduling-form ml-3"
                >
                  <v-flex xs2>
                    <p>
                      {{ dayDescriptions[d.day] }}
                    </p>
                  </v-flex>
                  <v-flex xs3>
                    <CRSelect
                      :id="`default-scheduling-select-driver-schedule-start-time-${index}`"
                      v-model="d.startTime"
                      class="cr-input"
                      :items="timeOptions"
                      prepend-inner-icon="icon-time"
                      flat
                      solo
                      :disabled="!d.available"
                      :placeholder="`--:--`"
                      :menu-props="{ auto: true, overflowY: true }"
                    />
                  </v-flex>
                  <v-flex xs3 offset-xs1>
                    <CRSelect
                      :id="`default-scheduling-select-driver-schedule-end-time-${index}`"
                      v-model="d.endTime"
                      class="cr-input"
                      :items="timeOptions"
                      prepend-inner-icon="icon-time"
                      flat
                      solo
                      :disabled="!d.available"
                      :placeholder="`--:--`"
                      :menu-props="{ auto: true, overflowY: true }"
                    />
                  </v-flex>
                  <v-flex xs1 offset-xs1>
                    <v-switch v-model="d.available" color="green"></v-switch>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import driverDefaultSchedules from '@/services/driverDefaultSchedules'
import { authComputed } from '@/state/helpers'

export default {
  components: {},
  filters: {
    formatTime(value, zone) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    formatDateTime(time, stop) {
      if (!time) return '- · -'
      return `${DateTime.fromISO(time, { zone: stop.TimeZone })?.toLocaleString(
        DateTime.DATE_SHORT
      )} · ${DateTime.fromISO(time, {
        zone: stop.timeZone,
      })?.toLocaleString(DateTime.TIME_SIMPLE)}`
    },
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'view',
    },
  },
  data() {
    return {
      newDriver: false,
      expand: false,
      timeOptions: [],
      dayDescriptions: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      schedule: [
        {
          day: 0,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
        {
          day: 1,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
        {
          day: 2,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
        {
          day: 3,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
        {
          day: 4,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
        {
          day: 5,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
        {
          day: 6,
          available: false,
          startTime: '09:00',
          endTime: '17:00',
          active: true,
          companyId: this.row.companyId,
          userId: this.userId,
          timezone: '',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    timezone() {
      return DateTime.local().zoneName
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeDispatch() {
      return this.mode === 'dispatch'
    },
  },
  async mounted() {
    this.generateTimeOptions()
    this.getSchedule()
  },
  methods: {
    async getSchedule() {
      if (!this.userId) {
        return
      }
      const scheduleResponse = await driverDefaultSchedules.getDriverDefaultSchedule(
        this.userId
      )
      const currSchedule = scheduleResponse.data
      if (currSchedule.length > 0) {
        for (const day of currSchedule) {
          Object.assign(this.schedule[day.day], day)
        }
      } else {
        this.newDriver = true
      }
    },
    generateTimeOptions() {
      const baseTimes = [...Array(24).keys()]
      const minutes = [':00', ':15', ':30', ':45']
      for (const baseTime of baseTimes) {
        const isPm = baseTime > 11
        for (const minute of minutes) {
          const newTimeOption = {}
          newTimeOption.value = `${
            baseTime > 9 ? baseTime : `0${baseTime}`
          }${minute}`
          if (isPm) {
            newTimeOption.text = `${
              baseTime === 12 ? baseTime : baseTime % 12
            }${minute} PM`
          } else {
            newTimeOption.text = `${baseTime === 0 ? 12 : baseTime}${minute} AM`
          }
          this.timeOptions.push(newTimeOption)
        }
      }
    },
    refresh() {
      setTimeout(this.getSchedule, 1000)
    },
    async submit() {
      for (const day of this.schedule) {
        day.timezone = this.timezone
      }
      if (this.newDriver) {
        await driverDefaultSchedules.createDriverDefaultSchedule(this.schedule)
      } else {
        await driverDefaultSchedules.updateDriverDefaultSchedule(this.schedule)
      }
      this.$store.dispatch('app/showAlert', { message: 'Schedule saved.' })
      this.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.form-container {
  background-color: $blue-light;
}

.default-scheduling {
  position: relative;
}

::v-deep {
  .v-table__overflow {
    border: 1px solid $gray-light;

    table.v-datatable {
      thead tr {
        height: 55px;
      }

      tbody {
        tr td:first-of-type {
          text-align: center;
        }
      }
    }
  }
}

.edit-button {
  width: auto;

  ::v-deep button {
    padding: 4px;
  }
}

.reservation-vehicle-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $primary;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
    }
  }
}

.scheduling-form {
  flex: 1 1 50%;
  max-width: 600px;
  margin-right: 3%;
}

.status-chip {
  font-weight: bold;
  color: $white;
  font-size: 11px;
  padding: 4px 5px 4px 5px;
  border-radius: 8px;

  &.todo {
    background-color: $blue-dull;
  }
  &.enroute {
    background-color: $primary;
  }
  &.completed {
    background-color: $green;
  }
}

.cr-input {
  label {
    display: block;
    margin-bottom: 4px;

    .v-icon {
      font-size: 1.2em;
    }
  }

  input {
    color: $gray;
    background: $secondary;
    border: 2px solid $secondary;
    outline: none;
    box-shadow: none;

    &::placeholder {
      color: $gray-medium-light;
    }

    &:hover {
      border-color: $gray-light;
    }

    &:focus,
    &:focus:hover {
      border-color: $primary;
    }
  }

  .dropdown-item {
    font-size: 1rem;
  }

  ::v-deep {
    .v-icon {
      color: $primary;
    }

    .icon-date-single {
      font-size: 15px;
    }

    .icon-book-it {
      font-size: 17px;
    }
  }
}
</style>
