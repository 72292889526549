import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { DefaultSchedule } from '@/models/dto/Driver'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getDriverDefaultSchedule(userId: number): Promise<AxiosResponse<DefaultSchedule>> {
    const host = baseUrl()
    const url = `https://${host}/default-schedule/user/${userId}`
    return httpService.get(url)
  },
  createDriverDefaultSchedule(payload: DefaultSchedule): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/default-schedule`
    return httpService.post(url, payload)
  },
  updateDriverDefaultSchedule(payload: DefaultSchedule): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/default-schedule`
    return httpService.patch(url, payload)
  },
}
