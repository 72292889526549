var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"driver-sidebar",attrs:{"fluid":""}},[_c('v-form',{ref:"base"},[_c('v-card',{staticClass:"card main-container"},[_c('v-layout',{staticStyle:{"max-width":"1200px","margin":"0 auto"},attrs:{"row":"","wrap":"","justify-center":"","align-end":""}},[_c('v-flex',{attrs:{"column":"","xs12":""}},[_c('div',{staticClass:"avatar-group"},[_c('input',{staticClass:"avatar-input",attrs:{"id":`users-form-input-upload-file`,"disabled":_vm.disabled,"accept":"image/*","type":"file"},on:{"change":_vm.uploadFile}}),_c('v-avatar',{staticClass:"the-avatar",attrs:{"size":"90"}},[(_vm.avatarLink)?_c('img',{attrs:{"src":_vm.avatarLink}}):_vm._e(),(!_vm.avatarLink)?_c('v-icon',{attrs:{"color":"blue","size":"50"}},[_vm._v(" person_outline ")]):_vm._e()],1),(!_vm.avatarLink)?_c('div',{staticClass:"avatar-text"},[_vm._v(" Add Profile Image ")]):_c('div',{staticClass:"avatar-text"},[_vm._v("Change Profile Image")])],1)]),_c('v-flex',{attrs:{"column":"","xs12":""}},[_c('CRInput',{attrs:{"id":`users-form-input-first-name`,"disabled":_vm.disabled,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'First Name Is Required',
                error: 'First Name Is Required',
              }),
            ],"floatinglabel":"First Name"},on:{"change":_vm.syncInput},model:{value:(_vm.row.firstName),callback:function ($$v) {_vm.$set(_vm.row, "firstName", $$v)},expression:"row.firstName"}})],1),_c('v-flex',{attrs:{"column":"","xs12":""}},[_c('CRInput',{attrs:{"id":`users-form-input-last-name`,"disabled":_vm.disabled,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Last Name Is Required',
                error: 'Last Name Is Required',
              }),
            ],"floatinglabel":"Last Name"},on:{"change":_vm.syncInput},model:{value:(_vm.row.lastName),callback:function ($$v) {_vm.$set(_vm.row, "lastName", $$v)},expression:"row.lastName"}})],1),_c('v-flex',{attrs:{"column":"","xs12":""}},[_c('CRInput',{class:{ 'left-input': _vm.$cr.breakpoint.smAndUp },staticStyle:{"margin-top":"2px"},attrs:{"id":`users-form`,"disabled":_vm.disabled,"rules":[
              _vm.isRequired(true, _vm.validateEmail, {
                req: 'Email Required',
                error: 'Email must be valid',
              }),
            ],"floatinglabel":"Email"},on:{"change":_vm.syncInput},model:{value:(_vm.row.email),callback:function ($$v) {_vm.$set(_vm.row, "email", $$v)},expression:"row.email"}})],1),_c('v-flex',{attrs:{"column":"","xs12":""}},[_c('label',[_vm._v("User Group")]),_c('div',{attrs:{"id":"users-form-select-group-container"}},[_c('CRSelect',{attrs:{"id":`users-form-select-group-id`,"solo":"","flat":"","item-value":"groupId","item-text":"groupName","rules":[
                _vm.isRequired(true, _vm.isNotEmpty, {
                  req: 'User Group Is Required',
                  error: 'User Group Is Required',
                }),
              ],"disabled":_vm.isModeView || _vm.isModeDriverAdd || true ? true : _vm.groupIdVerify,"items":_vm.getGroups()},on:{"change":_vm.getGroupAndSyncInput},model:{value:(_vm.row.groupId),callback:function ($$v) {_vm.$set(_vm.row, "groupId", $$v)},expression:"row.groupId"}})],1)]),_c('v-flex',{attrs:{"xs6":""}}),(_vm.row.groupId === 1 || _vm.row.groupId === 2)?_c('v-checkbox',{staticClass:"amenity-box",attrs:{"id":`users-form-checkbox-assign-tickets`,"label":"This user can be assigned tickets","disabled":_vm.disabled},on:{"change":_vm.assignTicketCheckBox},model:{value:(_vm.canBeAssignedTickets),callback:function ($$v) {_vm.canBeAssignedTickets=$$v},expression:"canBeAssignedTickets"}}):_vm._e()],1),_c('UsersFormDriverSettingsTab',{ref:"driverSettings",attrs:{"id":"users-form-driver-settings-tab-sidebar","mode":_vm.mode,"row":_vm.row,"user-id":_vm.id,"is-user-driver":_vm.isUserDriver,"selected-roles":_vm.roleSelections,"is-driver-sidebar":true,"minimum-requirements":_vm.minimumRequirements},on:{"user-role-change":function($event){_vm.roleSelections = $event}}})],1)],1),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('CRButton',{staticClass:"driver-sidebar--action-btn",attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.submit}},[(!_vm.loading)?_c('span',[_vm._v("Add Driver")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }