<template>
  <v-container fluid>
    <div>Add a time off date range by clicking Add New and saving</div>
    <DataTable v-bind="tableProps" class="mt-2" />
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'
import { authComputed } from '@/state/helpers'
import DataTable from '@/components/DataTable.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  '', // could end up needed to be 'drivers' if you use a standard crud format
  [
    'approve-driver-time-off',
    'reject-driver-time-off',
    'edit-driver-time-off',
    'delete-driver-time-off',
  ],
  ''
)

const columns = [
  {
    _t_id: '7e72062f',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '802c4331',
    prop: 'description',
    text: 'Description',
    sort: false,
    filter: false,
    type: 'text',
    defaultHidden: false,
  },
  {
    _t_id: 'd4cf5378',
    prop: 'requestedOn',
    text: 'Requested',
    computedText: (item) =>
      item === null
        ? ''
        : DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
    sort: false,
    filter: false,
    type: 'date',
    sortProp: 'requestedDate',
  },
  {
    _t_id: '04239494',
    prop: 'startDate',
    text: 'Start',
    computedText: (item) =>
      item === null
        ? ''
        : `${DateTime.fromISO(item).toLocaleString(
            DateTime.DATE_SHORT
          )}  ·  ${DateTime.fromISO(item).toLocaleString(
            DateTime.TIME_SIMPLE
          )}`,
    sort: false,
    filter: false,
    type: 'date',
    sortProp: 'startDate',
  },
  {
    _t_id: '3a67f4d2',
    prop: 'endDate',
    text: 'End',
    computedText: (item) =>
      item === null
        ? ''
        : `${DateTime.fromISO(item).toLocaleString(
            DateTime.DATE_SHORT
          )}  ·  ${DateTime.fromISO(item).toLocaleString(
            DateTime.TIME_SIMPLE
          )}`,
    sort: false,
    filter: false,
    type: 'date',
    sortProp: 'endDate',
  },
  {
    _t_id: 'f5ffe953',
    prop: 'status/label',
    text: 'Status',
    sort: false,
    filter: false,
    filterType: 'contains',
    type: 'text',
    sortProp: 'status',
  },
]

export default {
  components: {
    DataTable,
  },
  filters: {
    formatTime(value, zone) {
      if (!value) return ''
      return DateTime.fromISO(value, { zone }).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    formatDateTime(time, stop) {
      if (!time) return '- · -'
      return `${DateTime.fromISO(time, { zone: stop.TimeZone })?.toLocaleString(
        DateTime.DATE_SHORT
      )} · ${DateTime.fromISO(time, {
        zone: stop.timeZone,
      })?.toLocaleString(DateTime.TIME_SIMPLE)}`
    },
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      default: '',
    },
    // driver: { type: Object, default: () => [] },
  },
  data() {
    return {
      expand: false,
      requests: [],
      tableProps: {
        tableId: 'quotes_table_view',
        total: 0,
        currentPage: 1,
        perPage: 10,
        list: [],
        changePage: this.changePage,
        isDetailed: false,
        loading: true,
        columns,
        enableSavedViews: false,
        addNewEnabled: true,
        addNewHandler: () => {
          return this.$store.dispatch('app/openDialog', {
            component: () => import('@/components/DriverTimeOffForm.vue'),
            data: {
              user: this.row,
              userId: this.userId,
              mode: 'add',
            },
          })
        },
      },
    }
  },
  computed: {
    ...authComputed,
    refresh() {
      const refresh = this.$store.state.driverTimeOffRequests.refresh
      return refresh
    },
  },
  watch: {
    refresh() {
      if (this.refresh) {
        setTimeout(this.getTable, 1000)
        this.$store.dispatch('driverTimeOffRequests/endRefresh')
      }
    },
  },
  async mounted() {
    this.getTable()
  },
  methods: {
    async getTable() {
      if (this.userId) {
        const params = {
          pageSize: this.itemsPerPage,
          page: this.currentPage,
          userId: this.userId,
        }
        this.tableProps.loading = true
        const actionName = 'driverTimeOffRequests/getDriverTimeOffRequests'
        const eventData = await this.$store
          .dispatch(actionName, params)
          .catch(() => ({}))

        const events = eventData.data
        this.tableProps.list = events
        this.tableProps.total = events?.count || 0
      }

      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.time-off-container {
  position: relative;
  background-color: $blue-light;
}

::v-deep {
  .v-table__overflow {
    border: 1px solid $gray-light;

    table.v-datatable {
      thead tr {
        height: 55px;
      }

      tbody {
        tr td:first-of-type {
          text-align: center;
        }
      }
    }
  }
}

.edit-button {
  width: auto;

  ::v-deep button {
    padding: 4px;
  }
}

.reservation-vehicle-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $primary;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
    }
  }
}

.status-chip {
  font-weight: bold;
  color: $white;
  font-size: 11px;
  padding: 4px 5px 4px 5px;
  border-radius: 8px;

  &.todo {
    background-color: $blue-dull;
  }
  &.enroute {
    background-color: $primary;
  }
  &.completed {
    background-color: $green;
  }
}

::v-deep .btn-primaryaction {
  margin-right: 1px !important;
}
</style>
